<template>
    <div>
      <div class="fullwidth bottom-line">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="profile-image"><ProfileImage :editable="false"/></div>
                <div class="profile-info">
                  <div class="col1">
                      <h1 class="profile-name">{{ driver.name }} 
                        <v-btn class="ma-1" outlined color="primary" icon tile x-small>
                          <v-icon small @click="()=>$router.push(`/drivers/${driver.id}/edit`)">mdi-pencil</v-icon>
                        </v-btn>
                        </h1>
                      <div class="text-info">No.{{ driver.employee_no }} | SSN: {{ driver.idcard }}</div>
                      <widgetStars :scores="driver.average_rates" />
                      <div class="profile-widget">
                        <widgetState title="Average Rates" :value="driver.average_rates | round(2)"/>
                        <widgetState title="Completed Orders" :value="driver.complete_orders"/>
                        <widgetState title="Customer Rates" :value="driver.was_rated" :border="false"/>
                      </div>
                  </div>
                  <div class="col2">
                      <p><span class="text-title">Phone : </span><span class="text-info">{{driver.phone}}</span></p>
                      <p><span class="text-title">Email : </span><span class="text-info">{{driver.email}}</span></p>
                      <div class="mt-5">
                        <widgetState title="Deposit" :value="deposit_status_name" :border="false"/>
                      </div>
                      <div class="profile-widget">
                        <widgetState title="Tier" :value="driver.tier_name"/>
                        <widgetState title="Step" :value="driver.step_name"/>
                        <widgetState title="Status" :value="driver.status_name" :border="false"/>
                      </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    <div class="fullwidth pt-2 report">

      <v-tabs slider-color="primary" >
        <v-tab>Trips Report</v-tab>
        <v-tab>Transaction Report</v-tab>
        <v-tab>Ratings</v-tab>
        <div class="tab-head-filter">
          <div class="filter-container">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="filterDate_formatted"
                  label="Date Range"
                  readonly
                  v-on="on"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDate"
                @change="getfilterDate()"
                range
              ></v-date-picker>
            </v-menu>
            <!-- <v-btn class="ma-1" outlined color="primary" icon tile > -->
            <v-btn class="ma-1 icon-dark" color="primary" icon tile>
              <v-icon small @click="reloadData()">mdi-autorenew</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Trips Report -->
        <v-tab-item class="pa-4 pt-2">
          <TripsReport :employee_id="driver.id"/>
        </v-tab-item>

        <!-- Transaction Report -->
        <v-tab-item class="pa-4 pt-2">
          <TransactionsReport :employee_id="driver.id"/>
        </v-tab-item> 

        <!-- Ratings -->
        <v-tab-item class="pa-4 pt-2">
          <RatingsReport :employee_id="driver.id"/>
        </v-tab-item> 
      </v-tabs>
    </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ProfileImage from '@/components/drivers/profile_image.vue';
import widgetStars from '@/components/common/widgetStars.vue';
import widgetState from '@/components/common/widgetState.vue';
import TripsReport from '@/components/drivers/report_trips.vue';
import TransactionsReport from '@/components/drivers/report_transactions.vue';
import RatingsReport from '@/components/drivers/report_ratings.vue';

export default {
  name: 'DriverDetails',
  components: {
      ProfileImage,
      widgetStars,
      widgetState,
      TripsReport,
      TransactionsReport,
      RatingsReport
  },
  data: () => ({
      // driver: [],
      statusOptions: [
        { value: 1 , text : 'Active'},
        { value: 2 , text : 'Inactive'},
      ],
      stepOptions : [
        // { id: 0, name : 'Any...'},
        // { id: 1, name : 'Lead'},
        // { id: 2, name : 'Lead Rejected'},
        // { id: 3, name : 'Lead Approved'},
        // { id: 4, name : 'Onboard Rejected'},
        // { id: 5, name : 'LN Registering'},
        { id: 6, name : 'Onboard'},
        { id: 7, name : 'New Driver'},
        { id: 8, name : '1112 Driver'},
      ],
      tab: null,
      items: [
        'Trips Report', 'Transaction Report', 'Ratings',
      ],
      datePicker: false,
      filterDate :  [ moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      reLoaded: false
  }),
  methods: {
    ...mapActions(['genCan','getDriverDetails','resetProfileImage','getDriverTripsReport','getDriverTransactionsReport','getDriverRaitingsReport']),
    getfilterDate(){
      this.datePicker = false;
    },
    reloadData(){
      var maxRange = 30;
      var diff = moment(this.filterDate[1]).diff(moment(this.filterDate[0]),'days');
      if(diff <= maxRange){
        this.getDriverTripsReport({ id: this.driver.id, filterDate: this.filterDate } );
        this.getDriverTransactionsReport({ id: this.driver.id, filterDate: this.filterDate });
        this.getDriverRaitingsReport({ id: this.driver.id, filterDate: this.filterDate });
      } else {
        alert('Please do not select date range over 30 days, which will effect loading performance.');
      }
    }
  },
  computed: {
    ...mapGetters(['DriverDetails','depositStatus']),
    driver() {
      var data;
      data = {
        id: this.DriverDetails.id,
        employee_no: this.DriverDetails.employee_no,
        name : this.DriverDetails.name_th,
        email : this.DriverDetails.email,
        phone : this.DriverDetails.phone,
        idcard : this.DriverDetails.ssn,
        site_id : this.DriverDetails.site_id,
        site_name : this.DriverDetails.site_name,
        selected_site : {
          value: this.DriverDetails.site_id,
          text: this.DriverDetails.site_name,
        },
        step: null,
        step_name: '',
        tier : null,
        tier_name : '',
        status : null,
        status_name : '',
        deposit_status : this.DriverDetails.deposit_status,
        equipments : this.DriverDetails.equipments,
        loginext_id : this.DriverDetails.loginext_id,
        loginext_username : this.DriverDetails.loginext_username,
        average_rates : this.DriverDetails.average_rates,
        complete_orders : this.DriverDetails.complete_orders,
        was_rated : this.DriverDetails.was_rated
      }
      if(this.DriverDetails.employee_approve_step){
        data.step = this.DriverDetails.employee_approve_step.id;
        data.step_name = this.DriverDetails.employee_approve_step.name;

      }
      if(this.DriverDetails.employee_tier){
        data.tier = this.DriverDetails.employee_tier.id;
        data.tier_name = this.DriverDetails.employee_tier.name;
      }
      if(this.DriverDetails.employee_status){
        data.status = this.DriverDetails.employee_status.id;
        data.status_name = this.DriverDetails.employee_status.name;
      }
      return data;
    },
    filterDate_formatted () {
        var date_format = this.filterDate.map(x => moment(x).format('DD-MM-YYYY'));
        return date_format.join(' - ');
    },
    deposit_status_name(){
        var s = this.depositStatus.filter( element => ( element.id == this.driver.deposit_status));
        return s[0] ? s[0].text : '';
    }
  },
  created() {
    this.genCan();
    this.getDriverDetails(this.$route.params.id);
    this.resetProfileImage();
  }
};
</script>

<style>
.fullwidth {
    margin: 0 -12px;  
}
.bottom-line{
  border-bottom: 1px solid #e0e0e0;
}
.v-tabs-bar {
    border-radius: inherit;
    height: 48px;
    border-bottom: 1px solid #e0e0e0 !important;
}
.report .v-toolbar input{
  font-size: 12px !important;
}
.report .v-data-table-header {
  background: #e0e0e0;
}
.report tbody td{
  font-size: 12px;
}
.profile-image {
  float:left;
  margin-right:20px;
}
.profile-name {
  color: #1E1E1E;
  font-size: 20px;
  font-family: Roboto;
  font-weight: normal;
  margin-bottom: 3px;
}
.profile-info {
  padding-left:130px;
}
.col1,.col2{
    height: 137px;
    width: 300px;
    position: relative;
    float: left;
}
.col1{
  border-right: 1px solid #ddd;
  margin-right: 20px
}
.col2{
  width:400px;
}
p{
  margin: 0px !important;
  line-height: 15px;
}
.text-info, p{
  color: #676767;
  font-size: 13px;
}
.text-title {
  color: #000;
}
.profile-widget{
  position:absolute;
  bottom:0;
}
.tab-head-filter{
    position: absolute;
    right: 10px;
    padding: 3px 10px;
    font-size: 12px !important;
    border-left: 1px solid #ccc;
    width: 300px;
}
.tab-head-filter input{
  font-size: 14px;
}
.filter-container{
  display: flex;
}
.filter-container .icon-dark{
  color: #fff !important;
  background: #1976d2
}
</style>